import * as React from "react";
import { SectionContainer } from "../layout";
import { FooterFrenchNavigation } from "./FooterFrenchNavigation";

const languageToFooterNavigation = {
  frenchNavigation: FooterFrenchNavigation,
};

const languageToAllRights = {
  frenchNavigation: "Omnidoc, tous droits réservés.",
  noNavigation: "Omnidoc, all rights reserved.",
};

/**
 * The bottom Foooter, language dependant. Defaults to FrenchNavigation
 * Contains the bottom navigation and the copyrights
 */
export const Footer = ({ navigationLanguage = "frenchNavigation" }) => {
  // If given language has no translation, defaults to the same as no navigation (english)
  const allRights =
    languageToAllRights[navigationLanguage] ||
    languageToAllRights["noNavigation"];

  return (
    <SectionContainer
      fullWidth
      removePaddingTop={navigationLanguage === "noNavigation"}
    >
      <footer className="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        {languageToFooterNavigation[navigationLanguage] && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12 ">
            {languageToFooterNavigation[navigationLanguage]}
          </div>
        )}
        <div className="border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            &copy; {new Date().getFullYear()} {allRights}
          </p>
        </div>
      </footer>
    </SectionContainer>
  );
};
